<!-- 查询栏 -->
<template>
  <div id="query">
    <div class="c-query">
      <slot name="front"></slot>
      <div class="item">
        <DatePicker :isTime="false" v-model="date"></DatePicker>
      </div>
      <div class="item">
        <!--<el-button type="primary" icon="el-icon-search" @click="onEvent('query')">查询</el-button>-->
        <el-button type="primary" icon="el-icon-download" @click="onEvent('excel')">{{title}}</el-button>
      </div>
      <div v-if="model=='30Min'" class="item">
        <el-button type="primary" icon="el-icon-download" @click="onEvent('excel_30Min')">导出全部水库数据(半小时为节点)</el-button>
      </div>
      <slot name="behind"></slot>
    </div>
    <div style="color:red;">*点击导出后出现无法下载/打开的情况，请关闭浏览器在线文档阅读插件，或更换浏览器后重试。</div>
  </div>
</template>

<script>
import DatePicker from '../components/DatePicker.vue';

export default {
  props: {
    title: {
      type: String,
      default: '导出全部数据到excel'   //默认显示导出excel按钮
    },
    model: {
      type: String,
      default: ''                   //默认不显示导出全部水库数据(半小时为节点)
    },
  },
  data() {
    return {
      device: '',
      deviceModel: '',
      date: [],

    }
  },
  components: {
    DatePicker
  },
  computed: {
    options() {
      return this.$store.getters.getCurDevices;
    }
  },
  watch: {
    options: {
      handler() {
        this.device = this.options[0].id;
        this.deviceModel = this.options[0].model;
      }
    },
    device: {
      handler() {
        this.$emit('query', this.device, this.deviceModel, this.date);
      }
    }
  },
  methods: {
    handlerDevice(val) {
      if (val) {
        let option = this.options.filter(e => e.id == val)
        this.deviceModel = option[0].model
      }

    },
    onEvent(command) {
      if (this.device == "") {
        this.common.cToast(this, "请选择设备");
        return;
      }
      if (this.date == null || this.date.length == 0) {
        this.common.cToast(this, "请选择日期");
        return;
      }
      switch (command) {
        case "query":
          this.$emit('query', this.device, this.deviceModel, this.date);
          break;
        case "excel":
          this.$emit('excel', this.device, this.deviceModel, this.date, "day");
          break;
        case "excel_30Min":
          this.$emit('excel', this.device, this.deviceModel, this.date, "30Minutes");
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    // 设备首选
    if (this.options.length > 0) {
      this.device = this.options[0].id;
      this.deviceModel = this.options[0].model;
    }
    // 默认日期
    let date = this.common.cGetDate();
    this.date = [date, date];
  }
}
</script>

<style lang="scss">
.item {
  margin-bottom: 10px;
}
</style>
