<template>
  <div id="exportData" v-loading="loading">
    <QueryDayawan :title="title" @excel="excel"></QueryDayawan>
  </div>
</template>

<script>
import QueryDayawan from '../../components/QueryDayawan.vue';

export default {
  data() {
    return {
      title: '导出全部内涝设备数据'
    }
  },
  components: {
    QueryDayawan
  },
  computed: {
  },
  watch: {
    // 监听路由变化更新页面
    $route(to, from) {
    }
  },
  filters: {
  },
  methods: {
    // 查询
    query(device, deviceModel, date) {
    },
    // 导出全部地埋设备到Excel
    excel(device, deviceModel, date) {
      console.log("startDate=" + date[0] + ",stopDate=" + date[1]);
      //*
      let json = {
        "cmd": "daYaWanExportDmWater",
        "startDate": date[0],
        "stopDate": date[1],
        //"fileExt": ".xlsx" //支持.csv和.xlsx,目前微信只支持.xlsx格式
      }
      this.ws.send(json);
      this.loading = true;
      //*/
    },
  },
  created() {
    let param = this.$route.query;
    this.type = param.type;
  },
  mounted() {
    // 导出result
    this.ws.addCallback('daYaWanExportDmWaterAck', (res) => {
      if (res.error == 0) {
        let fileUrl = res.fileUrl;

        let protocol = window.location.protocol; //返回url的协议部分
        let host = window.location.host; //返回url的主机部分
        // let testHref = "http://192.168.1.120:80/historyDownload/00391WES(2021.04.19)(1).xlsx";
        // window.location.href = testHref;
        console.log("download=" + res.fileUrl);
        window.location.href = res.fileUrl;
      }
    })
  }
}
</script>

<style lang="scss">
#exportData {
  .chart-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-row-gap: 1%; //行间距
    grid-column-gap: 1%; //列间距

    .chart {
      padding: 5%;
    }
  }

  .typeOnline {
    // 设备状态列
    .el-table th,
    .el-table td {
      &:nth-child(3) {
        text-align: left !important;
        padding-left: 5% !important;
      }
    }
  }

  // 重写element样式
  .el-date-editor--timerange {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .el-radio-group {
    margin-top: -3px;
  }

  .el-radio-button__inner {
    padding: 12px 15px;
  }

  .emty-class {
    color: #909399;
    text-align: center;
    padding: 20px 0;
  }
}
</style>
